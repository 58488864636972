import React, { useState } from "react"
import { Link } from "gatsby"
import ContactForm from "../forms/contactForm"
import GdprDialogButton from "./Gdpr/GdprDialogButton"

import "../styles/navbar.scss"

const Footer = () => (
  <footer class="footer has-background-dark has-text-grey-dark">
    <div className="section" style={{ padding: "0 0.8rem" }}>
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-half">
            <h5 className="is-size-3">Kontakt</h5>
            <ContactForm />
          </div>
        </div>

        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <Link to={`/impressum`} className="has-text-grey-dark">
                Impressum
              </Link>
            </div>
            <div className="level-item">
              <Link to={`/datenschutz`} className="has-text-grey-dark">
                Datenschutz
              </Link>
            </div>
            <div className="level-item">
              <GdprDialogButton className="has-text-grey-dark" />
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/smj.augsburg"
                className="has-text-grey-dark"
              >
                Facebook
              </a>
            </div>
            <div className="level-item">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/augsburgsmj/"
                className="has-text-grey-dark"
              >
                Instagram
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
