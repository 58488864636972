import React, { useState } from "react"
import { Link } from "gatsby"
import { useForm } from "react-hook-form"

import { useStaticQuery, graphql } from "gatsby"

import { FaInfoCircle, FaExclamationTriangle } from "react-icons/fa"
import "./forms.scss"

export default function ContactForm() {
  const { register, handleSubmit, errors, reset } = useForm()
  const [loading, setLoading] = useState(false)
  const [requestError, setRequestError] = useState(false)

  const token = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            formToken
          }
        }
      }
    `
  ).site.siteMetadata.formToken

  const onSubmit = data => {
    if (data.name !== "" && data.name !== undefined) {
      return
    }
    setLoading(true)

    fetch(`https://smjadmin.jhgo.de/api/forms/submit/contact?token=${token}`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        form: {
          Name: data.name6ae999,
          Email: data.emaila88b7d,
          Nachricht: data.message68f414,
        },
      }),
    }).then(response => {
      if (response.ok) {
        setRequestError(false)
        setTimeout(() => {
          setLoading(false)
        }, 1000)
        reset()
      } else {
        setLoading(false)
        console.log("ERROR", response)
        setRequestError(true)
      }
    })
  }

  return (
    <form className="contactForm" onSubmit={handleSubmit(onSubmit)}>
      {/* --- Name --- */}
      <div className="field">
        <div className="control">
          <input
            type="text"
            placeholder="Ihr Name..."
            className={"input " + (errors.name6ae999 ? "is-danger" : "")}
            name="name6ae999"
            ref={register({ required: true })}
          />
        </div>
        {errors.name6ae999 && (
          <p class="help is-danger">
            <FaInfoCircle className="faIcon" />
            Bitte geben Sie Ihren Namen an.
          </p>
        )}
      </div>
      {/* --- Email --- */}
      <div className="field">
        <div className="control">
          <input
            type="text"
            placeholder="Ihre Email..."
            className={"input " + (errors.emaila88b7d ? "is-danger" : "")}
            name="emaila88b7d"
            ref={register({ required: true, pattern: /^\S+@\S+$/i })}
          />
        </div>
        {errors.emaila88b7d && (
          <p class="help is-danger">
            <FaInfoCircle className="faIcon" />
            Bitte geben Sie eine gültige Email an.
          </p>
        )}
      </div>
      {/* --- Message --- */}
      <div className="field">
        <div className="control">
          <textarea
            name="message68f414"
            placeholder="Ihr Anliegen..."
            className={"textarea " + (errors.message68f414 ? "is-danger" : "")}
            ref={register({ required: true })}
          />
        </div>
        {errors.message68f414 && (
          <p class="help is-danger">
            <FaInfoCircle className="faIcon" />
            Bitte teilen Sie uns Ihr Anliegen mit.
          </p>
        )}
      </div>
      {/* --- Privacy --- */}
      <div className="field">
        <div className="control">
          <label className="checkbox">
            <input
              type="checkbox"
              placeholder="Datenschutz"
              name="privacy_policy"
              className={errors.privacy_policy ? "is-danger" : ""}
              ref={register({ required: true })}
            />
            <span className={errors.privacy_policy ? "has-text-danger" : ""}>
              Die abgesendeten Daten werden nur zum Zweck der Bearbeitung Ihres
              Anliegens verarbeitet.
              <br /> Weitere Informationen finden Sie in unserer{" "}
              <Link to={`/datenschutz`}>Datenschutzerklärung</Link>.
            </span>
          </label>
        </div>
      </div>
      {/* --- Honey Pot --- */}
      <div className="field myName">
        <label className="label">Name</label>
        <div className="control">
          <input
            type="text"
            className={"input " + (errors.name ? "is-danger" : "")}
            name="name"
            ref={register()}
          />
        </div>
        {errors.name && (
          <p class="help is-danger">
            <FaInfoCircle className="faIcon" />
            Bitte geben Sie Ihren Namen an.
          </p>
        )}
      </div>
      {/* --- Submit --- */}
      <div class="control">
        <button
          className={
            "button is-fullwidth " +
            (loading ? " is-loading " : "") +
            (requestError ? " is-danger " : " is-primary is-outlined ")
          }
          type="submit"
        >
          {!requestError ? (
            "Abschicken"
          ) : (
            <>
              <FaExclamationTriangle className="faIcon" /> Aktuell können keine
              Anfragen bearbeitet werden!
            </>
          )}
        </button>
      </div>
    </form>
  )
}
