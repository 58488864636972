import React, { useState } from "react"
import { Link } from "gatsby"

import "../styles/navbar.scss"

import logo from "../images/smj-augsburg-logo.svg"
import { FaFacebookF, FaInstagram } from "react-icons/fa"

const brand = {
  color: "#ffffff",
}

const routeItem = {
  fontSize: "0.75rem",
  paddingTop: "0.68rem",
}

function Navbar() {
  const [navbarIsActive, setNavbarIsActive] = useState(false)

  const toggleMenu = () => {
    setNavbarIsActive(!navbarIsActive)
  }

  return (
    <nav
      className="navbar is-dark is-fixed-top"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="container">
        <div className="navbar-brand">
          <Link to="/" className="navbar-item" style={brand}>
            <img
              src={logo}
              style={{
                height: "2.3rem",
                width: "2.3rem",
                marginBottom: 0,
                marginRight: "4px",
              }}
            />
            -Augsburg
          </Link>

          <a
            role="button"
            className={
              "navbar-burger burger " + (navbarIsActive ? "is-active" : "")
            }
            onClick={toggleMenu}
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div
          id="navbarBasicExample"
          className={
            "navbar-menu is-dark " + (navbarIsActive ? "is-active" : "")
          }
          style={{ backgroundColor: "inherit" }}
        >
          <div className="navbar-start">
            <Link
              to="/"
              className="navbar-item is-uppercase has-text-grey-light"
              style={routeItem}
            >
              Home
            </Link>
            <Link
              to="/ueber-uns"
              className="navbar-item is-uppercase has-text-grey-light"
              style={routeItem}
            >
              Über Uns
            </Link>
            <a
              className="navbar-item is-uppercase has-text-grey-light"
              href="https://shops.his-print-service.de/schoenstattjugend-augsburg"
              style={routeItem}
            >
              Shop
            </a>
          </div>

          <div className="navbar-end">
            <a
              className="navbar-item has-text-grey-light"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/smj.augsburg"
            >
              <FaFacebookF />
            </a>
            <a
              className="navbar-item has-text-grey-light"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/augsburgsmj/"
            >
              <FaInstagram />
            </a>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
