/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({ title, description, image, pathname, article, lang }) => {
  const siteDefault = useStaticQuery(
    graphql`
      query defaultMeta {
        site {
          siteMetadata {
            title
            titleTemplate
            description
            siteUrl
            image
          }
        }
      }
    `
  ).site.siteMetadata

  const seo = {
    title: title || siteDefault.title,
    titleTemplate: siteDefault.titleTemplate,
    description: description || siteDefault.description,
    image: `${siteDefault.siteUrl}${image || siteDefault.image}`,
    url: `${siteDefault.siteUrl}${pathname || "/"}`,
    article: article ? `article` : `website`,
  }

  return (
    <Helmet
      defer={false}
      htmlAttributes={{
        lang,
      }}
      title={seo.title}
      titleTemplate={seo.titleTemplate}
      meta={[
        {
          name: `description`,
          content: seo.description,
        },
        {
          name: `image`,
          content: seo.image,
        },
        // === og ===
        {
          property: `og:site_name`,
          content: siteDefault.title,
        },
        {
          property: `og:title`,
          content: seo.title,
        },
        {
          property: `og:description`,
          content: seo.description,
        },
        {
          property: `og:image`,
          content: seo.image,
        },
        {
          property: `og:type`,
          content: seo.article,
        },
        {
          property: `og:url`,
          content: seo.url,
        },

        // Twitter
        {
          name: `twitter:title`,
          content: seo.title,
        },
        {
          name: `twitter:description`,
          content: seo.description,
        },
        {
          name: `twitter:image`,
          content: seo.image,
        },
        {
          name: `twitter:card`,
          content: seo.article ? `summary_large_image` : `summary`,
        },
      ]}
    />
  )
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null,
  article: false,
  lang: `de`,
}

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
  lang: PropTypes.string,
}

export default SEO
