import React from "react"
import { GdrpContext } from "./GdprContext"
import * as styles from "./GdprSettings.module.scss"
import classnames from "classnames"

export default function GdprSettings() {
  const { dispatch, settings, changed } = React.useContext(GdrpContext)

  let initialSettings = {}
  for (const service of settings.services) {
    if (changed) {
      initialSettings[service.name] = service.allowed
    } else {
      initialSettings[service.name] = service.default
    }
  }
  const [settingsForm, setSettingsForm] = React.useState(initialSettings)

  const closeSettings = e => {
    e.preventDefault()
    dispatch({ type: "HIDE_SETTINGS" })
    // dispatch({ type: "HIDE_DIALOG" })
  }

  const changeCheckbox = (key: string) => {
    let newS = { ...settingsForm }
    newS[key] = !settingsForm[key]
    setSettingsForm(newS)
  }

  const save = () => {
    dispatch({
      type: "SAVE_SETTINGS",
      settings: settingsForm,
    })
    dispatch({ type: "HIDE_SETTINGS" })
    dispatch({ type: "HIDE_DIALOG" })
  }

  return (
    <div className="modal is-active">
      <div className="modal-background" onClick={closeSettings}></div>
      <div className="modal-card">
        <header className="modal-card-head has-background-dark">
          <p
            className="modal-card-title has-text-white"
            style={{ marginBottom: 0 }}
          >
            Privatsphäre Einstellungen
          </p>
          <button
            className="delete"
            aria-label="close"
            onClick={closeSettings}
          ></button>
        </header>
        <section className="modal-card-body">
          {settings.categories.map(category => (
            <div key={category}>
              <span className="title">{category}</span>

              {settings.services.map(service => {
                if (service.category !== category) {
                  return null
                }

                let dataInUse = ""
                for (const cookie of service.cookies) {
                  if (dataInUse.length > 0) {
                    dataInUse += ", "
                  }
                  dataInUse += cookie.type + ": " + cookie.name
                }

                return (
                  <div
                    className="columns is-mobile"
                    key={category + "-" + service.name}
                  >
                    <div className="column is-1 has-text-right">
                      <label className="checkbox">
                        <input
                          className={styles.checkbox}
                          type="checkbox"
                          checked={settingsForm[service.name]}
                          onChange={() => {
                            changeCheckbox(service.name)
                          }}
                        />
                      </label>
                    </div>
                    <div className="column">
                      <span className="is-size-3">{service.name}</span>
                      <br />
                      <p>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: service.description,
                          }}
                        ></span>
                        <br />

                        <span className="is-size-7">
                          {" "}
                          Gesetzte Daten: {dataInUse}{" "}
                        </span>
                        <span className="is-size-7">
                          ; Benuzte Services: {service.domain}{" "}
                        </span>
                      </p>
                    </div>
                  </div>
                )
              })}
            </div>
          ))}
        </section>
        <footer className={classnames([styles.modalFooter, "modal-card-foot"])}>
          <button className="button is-primary" onClick={save}>
            Speichern
          </button>
        </footer>
      </div>
    </div>
  )
}
